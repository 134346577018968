<template>
  <div class="content">
    <div class="block block-rounded">
      <div class="block-header block-header-default">
        <h3 class="block-title">All Accounts</h3>
      </div>
      <div class="block-content">
        <DataTable
          :items="accounts"
          :total="totalAccounts"
          :loading="loadingAction.list"
          :columns="columns"
          :actions="actions"
          :fetch-items="refreshTable"
        >
          <template v-slot:name="slotProps">
            <router-link class="font-w600" :to="{ name: 'account-view', params: { id: slotProps.data._id } }">{{ slotProps.data.name }}</router-link>
          </template>

          <template v-slot:countryCode="slotProps">
            {{ slotProps.data.registeredAddress.countryCode.toUpperCase() }}
          </template>

          <template v-slot:ocUpdatedDate="slotProps">
            {{ slotProps.data.ocUpdatedDate | date('ddd D MMM YYYY HH:mm:ss') }}
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import DataTable from '@/components/DataTable';

export default {
  name: 'AccountList',
  components: {
    DataTable
  },
  data() {
    return {
      columns: [{ name: 'Name', code: 'name' }]
    };
  },
  computed: {
    ...mapGetters({
      accounts: 'account/accounts',
      totalAccounts: 'account/total',
      loadingAction: 'account/loadingAction',
      countries: 'util/countries'
    }),
    actions() {
      return [
        {
          label: 'Edit',
          class: 'btn-primary',
          icon: 'pencil',
          route: 'account-edit',
          params: { id: '_id' }
        }
      ];
    }
  },
  created() {
    this.listCountries();
  },
  methods: {
    ...mapActions({
      listAccounts: 'account/list',
      listCountries: 'util/listCountries'
    }),
    refreshTable(...params) {
      this.listAccounts(...params);
    }
  }
};
</script>
